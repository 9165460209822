import React from 'react'
const DemoVideos = () => {
    return (
        <div>
            <section className="product" id="#videos">
                <div className="text-xl sm:text-3xl text-white bg-mainColor p-1">Watch Demo Videos </div>
                <p className="sm:text-xl text-textColor m-3">Learn more by watching our demo videos.</p>
                <div className="products">
                    <div className="items">
                        <div className=" h-60 bg-mainColor m-2">

                        </div>
                        <h4>Demo video 1</h4>
                        <button className="videoButton">Watch full video</button>
                    </div>
                    <div className="items">
                        <div className=" h-60 bg-mainColor m-2">

                        </div>
                        <h4>Demo video 2</h4>
                        <button className="videoButton">Watch full video</button>
                    </div>

                </div>
            </section> 
        </div>
    )

}
export default DemoVideos