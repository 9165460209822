import React from 'react'
import FadeIn from 'react-fade-in'
import download from '../../images/download.svg'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Link } from 'gatsby'
const ShortDescription = () => {
    return (       
        <div className="py-16 items-center bg-white justify-center" id="download">
            <div className="max-w-screen-xl w-4/5 md:w-full m-auto grid md:grid-cols-2 grid-cols-1">
                <div className="mx-4 flex justify-center items-center">
                    <FadeIn delay="300" transitionDuration="700">
                        <p className="text-xl sm:text-3xl p-1 font-bold text-left">Smart Software That Makes Browsing Painless and Relaxing.</p>
                        <div className="mb-3  m-auto text-left">
                        
                            <p className="text-lg"> <CheckCircleIcon fontSize="large" className="mr-2 text-yellowColor"/> The all-new BricoBrowser is a next generation browser software that makes easy, fast and secure  your browsing personal and professional tasks. </p>
                            <p className="text-lg"> <CheckCircleIcon fontSize="large" className="mr-2 text-yellowColor"/> Spend less time on preparing for your tasks and more time doing the browsing. </p>
                        </div>
                        <div className="flex justify-start">
                            <Link to="/download"><button className="bg-mainColor text-white p-3 rounded-md m-2 animate-pulse font-bold leading-relaxed">Download App for Free</button></Link>
                        </div>
                    </FadeIn>
                </div>
                <div className="flex items-center lg:w-2/3 m-auto">
                    <img src={download} className=""/>
                </div>
            </div>
        </div>
    )
}
export default ShortDescription