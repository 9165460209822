import React from 'react'
import ricta1 from '../../images/ricta1.svg'
import ricta2 from '../../images/ricta2.svg'
import ricta3 from '../../images/ricta3.svg'
import ricta4 from '../../images/ricta4.svg'
import { Link } from 'gatsby'
const RictaBrowser = () => {
    return (
        <div className="bg-bgColor">
            <p className="text-xl sm:text-3xl p-1 text-center bg-mainColor text-white">What do we want to solve?</p>
            <section className="max-w-screen-xl w-4/5 md:w-full m-auto">
                <div className="grid grid-cols-1 md:grid-cols-3">
                    <div className="px-3">
                        <div className="w-1/2 m-auto  lg:h-3/6">
                            <img src={ricta1}/>
                        </div>
                        <p className="text-left p-2 mt-4 leading-relaxed text-lg">Do you listen to music or watch movie while browsing the web, replying to email and scrolling your social media newsfeed?</p>
                    </div>
                    <div className="px-3">
                        <div className="w-1/2 m-auto lg:h-3/6">
                            <img src={ricta2}/>
                        </div>
                        <p className="text-left p-2 mt-4 leading-relaxed text-lg">We all get tired flipping through different browsers and logging in/out from our multiple identity profiles. With BricoBrowser, social media-multitasking is made easy.</p>
                    </div>
                    <div className="px-3">
                        <div className="w-1/2 m-auto  lg:h-3/6 ">
                            <img src={ricta3}/>
                        </div>
                        <p className="text-left p-2 mt-4 leading-relaxed text-lg">Do you need to access online content from multiple geolocation at the same time and find it very frustrating to use multiple virtual machine or changing VPN each time?</p>
                    </div>

                </div>
                
            </section>
            <div className="py-16 items-center justify-center">
                <div className="max-w-screen-xl w-4/5 md:w-full m-auto grid md:grid-cols-2 grid-cols-1">
                    <div className="flex items-center w-1/2 m-auto my-2">
                        <img src={ricta4}/>
                    </div>
                    <div className="mx-4 flex flex-col justify-center">
                        <p className="text-xl sm:text-3xl p-1 font-bold text-left">BricoBrowser was built to help you</p>
                        <div className="text-left">
                            <p className="leading-relaxed text-lg"> BricoBrowser lets you have in the same window up to 4 screens, each of them using a different identity and a different proxy.</p>
                            <div className="flex justify-start">
                                <Link to="/download"><button className="bg-mainColor text-white p-3 rounded-md m-2 font-bold leading-relaxed">Download App for Free</button></Link>
                            </div>
                        </div>
                 
                    </div>
             
                </div>
            </div>
        </div>
    )
}
export default RictaBrowser