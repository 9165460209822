import React, { useContext } from 'react'
import { featuresData } from '../../data/products'
import { FeatureContext } from '../hooks/context'
import { navigate } from 'gatsby' 

const Products = () => {
    const { activeFeature, setActiveFeature } = useContext(FeatureContext)
    const setFeature = (feature) => {
        setActiveFeature(feature.title)
        navigate('/features/')
    }
    return (
        <div>
            <section className="product" id="features">
                <div className="text-xl sm:text-3xl text-white bg-mainColor p-1">Features </div>
                <p className="sm:text-xl text-textColor p-2 mt-2 ">Check out our list of awesome features and functionalities we developed for your delightment.</p>
                <div className="products">
                    {featuresData.map((feature,i)=>(
                        <div onClick={()=>setFeature(feature)} className="items" key={i}>
                            {feature.icon}
                            <h4>{feature.title}</h4>
                            <p>{feature.subtitle}</p> 
                        </div>
                    ))}
                </div>
            </section>
        </div>
    )

}
export default Products