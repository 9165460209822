/* eslint-disable react/prop-types */
import React from 'react'
import introductionImg from '../../images/introductionImg.svg'

const Introduction = () => {
    return (
        <div className="bg-bgColor  animate-fade-in-up bg-no-repeat bg-center bg-cover resize">
            <div className="flex items-center justify-center pt-16 w-4/5 md:w-full max-w-screen-xl m-auto">
                <div className="mx-2 text-center text-black">
                    <h1 className="mb-4  text-3xl md:text-6xl font-bold">
                        Become a multi-tasking browsing grand master !
                    </h1>

                    <div className=" mb-3 text-lg">
                    Enjoy multi profile multi proxy browsing sessions, build and organize <br/> your workspaces as they best fit your daily operations, <br/> create and customize splitted screens to monitor different workspaces at the same time.
                    </div>
                    <div className="flex items-center justify-center">
                        <img src={introductionImg}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Introduction